
import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import Flights from "./FlightsData.js";
import Gui from "./gui.js";
import freeways from "./data/freeways.geojson";
import redcars from "./data/redcars.geojson";
import bbox from "@turf/bbox";

// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
mapboxgl.accessToken = 'pk.eyJ1IjoiamF3c2h2IiwiYSI6ImNrNnZmYncwdjAxYjAzcG16ZG1zaXY4NmoifQ.o6Dleq9Ibuv2JSXw9JDZcw';


export default function Map() {
  const mapContainer = useRef(null);

  const map = useRef(null);

  const mapRef = useRef(null);

  const [checkedItems, setCheckedItems] = useState({ "C_1930_4": true });

  const handleChange = (event) => {
    // updating an object instead of a Map
    setCheckedItems({
      ...checkedItems,
      [event.target.id]:
        event.target.checked
    });
  }


  console.log("ParentcheckedItems: ", checkedItems);


  useEffect(() => {
    let map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-v9",
      center: [-118.24, 34.05],
      zoom: 14.5,
      maxZoom: 17.2,
      minZoom: 10,
    });

    map.on("load", () => {

      map.addSource("freeways", {
        type: "geojson",
        data: freeways,
      });

      map.addSource("redcars", {
        type: "geojson",
        data: redcars,
      });


      map.addLayer({
        id: 'C_6630',
        type: 'raster',
        source: {
          type: 'raster',
          tiles: ['https://api.mapbox.com/v4/jawshv.C_6630_8bit/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiamF3c2h2IiwiYSI6ImNrNnZmYncwdjAxYjAzcG16ZG1zaXY4NmoifQ.o6Dleq9Ibuv2JSXw9JDZcw'],
        },
      });

      map.addLayer({
        id: 'C_2917',
        type: 'raster',
        source: {
          type: 'raster',
          tiles: ['https://api.mapbox.com/v4/jawshv.C_2917/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiamF3c2h2IiwiYSI6ImNrNnZmYncwdjAxYjAzcG16ZG1zaXY4NmoifQ.o6Dleq9Ibuv2JSXw9JDZcw'],
        },
      });

      map.addLayer({
        id: 'C_1930',
        type: 'raster',
        source: {
          type: 'raster',
          tiles: ['https://api.mapbox.com/v4/jawshv.C_1930_4/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiamF3c2h2IiwiYSI6ImNrNnZmYncwdjAxYjAzcG16ZG1zaXY4NmoifQ.o6Dleq9Ibuv2JSXw9JDZcw'],
        },
      });
      map.addLayer({
        id: 'C_7595',
        type: 'raster',
        source: {
          type: 'raster',
          tiles: ['https://api.mapbox.com/v4/jawshv.8_bit_C_7595/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiamF3c2h2IiwiYSI6ImNrNnZmYncwdjAxYjAzcG16ZG1zaXY4NmoifQ.o6Dleq9Ibuv2JSXw9JDZcw'],
        },
      });

      map.addLayer({
        id: 'C_24400',
        type: 'raster',
        source: {
          type: 'raster',
          tiles: ['https://api.mapbox.com/v4/jawshv.C_24400/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiamF3c2h2IiwiYSI6ImNrNnZmYncwdjAxYjAzcG16ZG1zaXY4NmoifQ.o6Dleq9Ibuv2JSXw9JDZcw'],
        },
      });

      map.addLayer({
        id: "freeways",
        type: 'fill',
        source: "freeways",
        paint: {
          'fill-color': "rgb(231, 137, 29)",
          'fill-opacity': .5,
        },
      });

      map.addLayer({
        id: "redcars",
        type: 'line',
        source: "redcars",
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': 'rgb(231, 69, 29)',
          'line-width': 3.5,
          'line-opacity': .7,
        },
      });

      map.addSource('mapbox-dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
        'tileSize': 512,
      });

      map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1 });

      map.addLayer({
        'id': 'sky',
        'type': 'sky',
        'paint': {
          'sky-type': 'atmosphere',
          'sky-atmosphere-sun': [1.0, 1.0],
          'sky-atmosphere-sun-intensity': 1
        }
      });

      map.setLayoutProperty('C_1930', 'visibility', 'none');
      map.setLayoutProperty('C_6630', 'visibility', 'none');
      map.setLayoutProperty('C_7595', 'visibility', 'none');
      map.setLayoutProperty('C_24400', 'visibility', 'none');
      map.setLayoutProperty('freeways', 'visibility', 'none');
      map.setLayoutProperty('redcars', 'visibility', 'none');

      mapRef.current = map;
      console.log("map loaded");
    });
  }, []);


  const newmaps = Object.entries(checkedItems);


  useEffect(() => {
    let map = mapRef.current;
    if (map) {
      // Ensure the mapbox API is ready to go, otherwise might error out here, could also get fancy and use try-catch.
      if (map.isStyleLoaded()) {

        newmaps.map((e) => {
          // TO ADD
          if (e[1]) {
            console.log("tru" + e[0])
            var layers = map.getStyle().layers;

            map.setLayoutProperty(e[0], 'visibility', 'visible');

          } else {
            console.log("false" + e[0])
            map.setLayoutProperty(e[0], 'visibility', 'none');
          }

        })

      }
    }

    // Check that the ref exists (may not be defined on first render)
  }, [handleChange]);

  return (

    <div>
        <Gui checkedItems={checkedItems} handleChange={handleChange} />
        <div ref={mapContainer} className="map-container" />
        </div>
  );
}


