import './App.css';
import { Link, Route, Switch } from "wouter";
import React, { useRef, useEffect, useState } from 'react';
import About from "./About.js"
import Map from "./map.js"

export default function App() {

  return (
    <Switch>
      <Route path="/about"><About /></Route>
      <Route><Map/></Route>
    </Switch>
  );
}


