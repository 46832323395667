import React, { useRef, useEffect, useState } from 'react';
import Flights from "./FlightsData.js";


const Gui = ({ checkedItems, handleChange }) => {
    useEffect(() => {
        Flights.map((e) => {
            return(
            document.getElementById(e.id).checked = false
            )
        })
        document.getElementById("C_2917").checked = true
    }, [])

    const [table, setTable] = useState(true)
    const set = () => setTable(!table)

    return (
        <div class="gui">
            <div>
            <h3>Aerial LA   <span><a href="/about">About</a></span></h3>
            <button onClick={set}>{table ? "-" : "+"}</button>
            </div>
            <table class={table ? "" : "hidden"}>
                {Flights.map((e, i) => {
                    return (
                        <tr key={i}>
                            <td>
                                <input id={e.id} type="checkbox" name="ortho"
                                    checked={checkedItems[e.id]}
                                    onChange={handleChange}
                                />
                            </td>
                            <td>
                            {e.display}
                            </td>
                            <td>
                            {e.year}
                            </td>
                            <td>
                            {e.area}
                            </td>
                            <td>
                            {e.url ? <a target="_blank" href={e.url}>Source</a> : ""}
                            </td>
                        </tr>
                    );
                })}
            </table>


        </div>
    );
}

export default Gui;