
const Flights = [
{ 
        "id": "C_1930",
        "display":"C-1930",
        "year": 1931,
        "area": "Pico/Olympic through DTLA",
        "url": "https://mil.library.ucsb.edu/ap_images/c-1930/"

},
{ 
        "id": "C_2917",
        "display":"C_2917",
        "year": 1934,
        "area": "Union Station under construction",
        "url": "https://mil.library.ucsb.edu/ap_images/c-2917/"
},

{ 
        "id": "C_6630",
        "display":"C_6630",
        "year": 1940,
        "area": "Westside - Santa Monica Mts Area",
        "url": "https://mil.library.ucsb.edu/ap_images/c-6630/"
},

{ 
        "id": "C_7595",
        "display":"C_7595",
        "year": 1941,
        "area": "La Brea / Crenshaw Area",
        "url": "https://mil.library.ucsb.edu/ap_images/c-7595/"
},

{ 
        "id": "C_24400",
        "display":"C_24400",
        "year": 1962,
        "area": "Central LA",
        "url": "https://mil.library.ucsb.edu/ap_images/C-24400/"
},

{ 
        "id": "redcars",
        "display":"Red Car Lines",
        "year": 1940,
        "area": "LA Basin",
        "url": "https://www.google.com/maps/d/u/0/viewer?mid=1DuFcDskRpjHGsavgjnpKZjA4xNQ&ll=33.94231750511631%2C-117.87755545&z=9"
},
{ 
        "id":"freeways",
        "display": "Freeway Outlines",
        "year": 2021,
        "area": "LA County",
        "url": null
}
]

export default Flights
